<template>
  <!-- Table Container Card -->
  <b-card no-body class="">
    <div class="m-2">
      <!-- Table Top -->.
      <h1>Breed Standard</h1>
      <b-row>
        <!-- Date picker -->
        <b-col cols="12" md="5" class="mt-2">
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  Breed Standard Type<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider #default="{ errors }" name="Breed Standard Type" rules="required">
                    <v-select v-model="breed" :options="breedStandardList" label="name" :clearable="false"
                      @input="getBreedDetailList" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- Search -->

        <!-- <b-col
          cols="12"
          md="7"
          class="mt-2 d-flex justify-content-end align-items-center"
        >
          <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getBreedStandardList()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button>
          <b-button v-b-modal.medicine variant="primary mr-1 ml-1">
            Add
          </b-button>
          <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </b-col> -->
      </b-row>
    </div>
    <div v-if="single_breed_list && single_breed_list.length > 0">
      <b-table :items="single_breed_list" responsive sticky-header :fields="header"
        empty-text="No matching records found" class="table-scroll">
      </b-table>

      <!-- <div class="mx-2 mb-2">
        <b-pagination
          :value="breedStandardList.current_page"
          :total-rows="breedStandardList.total"
          :per-page="breedStandardList.per_page"
          align="right"
          @change="getBreedStandardList"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div> -->
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img :src="
        require('@/assets/images/svg/infographics/no_data_infographic.svg')
      " class="mr-2" />
      <h4 class="mt-2 Secondary">Breed Standard List Empty</h4>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
// import { required, email, min_value, max_value, integer } from "@validations";

export default {
  components: {
    DeleteModal,
    vSelect,
  },
  data: () => ({
    searchQuery: null,
    single_breed_list: [],
    search_filter: {
      input_field: "",
      to_date: "",
      from_date: "",
    },
    breed: null,
    header: [],
    breedStandardList: [],
  }),
  methods: {
    getBreedStandardList(pageNo = 1) {
      const companyId = this.$route.params.companyId;
      let url = `web/breed-standards-list`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }

      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.breedStandardList = response.data.breedStandardList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getBreedDetailList(event) {
      axiosIns
        .get(`web/breed-standards/${event.name}`)
        .then((response) => {
          this.single_breed_list = response.data.singleBreedList;
          this.header = response.data.header;
          this.header.forEach((element) => {
            element.formatter = (value) => {
              return value ? value : "NA";
            };
          });
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    // excelDownload() {
    //   if (this.search_filter.from_date && this.search_filter.to_date) {
    //     this.search_filter.from_date = moment(
    //       this.search_filter.from_date
    //     ).format("YYYY-MM-DD");
    //     this.search_filter.to_date = moment(this.search_filter.to_date).format(
    //       "YYYY-MM-DD"
    //     );
    //   }
    //   let order_filter_data = this.prepareForm(this.search_filter);
    //   axiosIns
    //     .post(`web/export-vaccination-schedule`, order_filter_data)
    //     .then((response) => {
    //       var tempLink = document.createElement("a");
    //       tempLink.style.display = "none";
    //       tempLink.href = response.data.file;
    //       tempLink.setAttribute("download", response.data.name);
    //       if (typeof tempLink.download === "undefined") {
    //         tempLink.setAttribute("target", "_blank");
    //       }
    //       document.body.appendChild(tempLink);
    //       tempLink.click();
    //       document.body.removeChild(tempLink);
    //       this.search_filter.to_date = null;
    //       this.search_filter.from_date = null;
    //       this.search_filter.input_field == null;
    //       this.getBreedStandardList();
    //     })
    //     .catch((error) => {
    //       error.response;
    //       const data = error.response.data.errors;
    //       data, "data";
    //       let arr = Object.values(data).flat().toString(",");
    //       arr, "array";
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: "top-right",
    //         props: {
    //           icon: "CoffeeIcon",
    //           variant: "danger",
    //           text: arr,
    //         },
    //       });
    //       this.search_filter.to_date = null;
    //       this.search_filter.from_date = null;
    //       this.search_filter.input_field == null;
    //       this.getBreedStandardList();
    //     });
    // },
    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getBreedStandardList();
    },
  },

  created() {
    this.getBreedStandardList();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.activeClass {
  background-color: #28c76f;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  justify-content: center;
}

.inactiveClass {
  background-color: #ea5455;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  justify-content: center;
  border-radius: 10px;
}

.header-title {
  height: 60px;
  border-bottom: 1px solid #ebe9f1;
  font-weight: 500;
  color: #636363;
  font-size: 1.714rem;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
}

.table-scroll {
  overflow-y: scroll;
}

.invoice-filter-select {
  min-width: 190px;

  // ::v-deep .vs__selected-options {
  // 	flex-wrap: nowrap;
  // }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
